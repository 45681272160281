import React, { useRef, createRef, useEffect, useState } from "react";

import RestaurantList from './restaurants/RestaurantList'
import SearchAppBar from './SearchAppBar'

const RestaurantsPage = () => {

    return (
        <>
            <SearchAppBar/>
            <RestaurantList/>
           
        </>
    )
}
export default RestaurantsPage