
import Box from '@mui/material/Box';
import Restaurant from './Restaurant';
import  Paper  from '@mui/material/Paper';
import { backendUrl } from "../../index";
import React, { useRef, createRef, useEffect, useState } from "react";
import Loading from '../Loading'
import Tag from '../Tag'
import {Typography} from '@mui/material'

import ScrollableMenu from '../scroll/ScrollableMenu'
import ScrollableTags from '../scroll/ScrollableTags'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function RestaurantList() {
    const [restaurants, setRestaurants] = useState()
    const [tags, setTags] = useState()

    async function loadRestaurants() {
        
        const response = await fetch(backendUrl + '/restaurants')
        const json = await response.json()
        if (response.ok) {
            setRestaurants(json)
        }
    }

    
    useEffect(() => {
        loadRestaurants()
    }, [])

    async function loadTags() {
        
        const response = await fetch(backendUrl + '/tags')
        const json = await response.json()
        if (response.ok) {
            setTags(json)
        }
    }

    
    useEffect(() => {
        loadTags()
    }, [])


    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    if (!restaurants) return <Loading/>
    if (!tags) return null


    const restaurantsList = restaurants.filter(restaurant => restaurant.active === true).map(restaurant => (
      <Box 
        key={restaurant.id}>  
        <Box mt={4} key={restaurant.id}>
            <Restaurant restaurant={restaurant}  widthAvatar={!matches ? "96vw" : "32vw"} widthBox={!matches ? "100vw" : "36vw"}/>

        </Box>
    </Box>))

    return (
        <>
            <Box ml={1}><ScrollableTags tags={tags} /></Box>
            <Box ml={1} mt={4} mb={2}>
                <Typography sx={{fontWeight: "bold"}} variant="h4">
                    Recomandari
                </Typography>
                <Typography sx={{marginLeft:"3px", fontWeight: "bold"}} variant="b2">
                    by Tomis Hotel
                </Typography>
            </Box>
            <ScrollableMenu restaurants={restaurants.filter(restaurant => restaurant.active === true)}/>

            <Box ml={1} mt={4} mb={4}>
                <Typography sx={{fontWeight: "bold"}} variant="h4">
                    Restaurante
                </Typography>
            </Box>

            {restaurantsList}

        </>
            
            )
}