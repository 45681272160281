import React, { useState, useRef , useEffect} from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import List from '@mui/material/List';
import { useCart } from '../context/cart/context'
import useFetch from 'use-http'
import { backendUrl } from '../index'
import {
  useHistory,
  useLocation, useParams
} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { IconButton, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CircularProgress from '@mui/material/CircularProgress';
import CartItem from './CartItem';
import { LoadingButton } from '@mui/lab';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import { useFeature, MIN_ORDER_VALUE} from '../context/feature/context'

const reducer = (accumulator, currentValue) => accumulator + currentValue;

export function getTotalSum(list) {
  return list
    .map(p => p.quantity * p.price)
    .reduce(reducer, 0)
}

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const CartDetailsPage = () => {
  const { restaurantId, tableId, orderId, restaurantKey } = useParams()

  const { t, i18n } = useTranslation();
  const history = useHistory()
  const restaurant = useLocation().state.restaurant
  const { state, dispatch } = useCart()
  const { get, post, response, loading, error } = useFetch(backendUrl)
  const [isLoading, setIsLoading] = useState(false)

  const { state: featureState } = useFeature()

  const minOrderValue = (!!restaurantKey && featureState.features.hasOwnProperty(MIN_ORDER_VALUE))  ? featureState.features[MIN_ORDER_VALUE] : 0

  useEffect(() => {
    if (state.sessionId) {
        fetch(backendUrl + '/aaaa', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            }, body: JSON.stringify({ tableId, restaurantId, restaurantKey, userId: state.userId, sessionId: state.sessionId, page: window.location.href + "/?userId=" + state.userId  })
        })
    }

}, [state.sessionId])


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const onClick = async () => {
    let type = "TABLE"

    setIsLoading(true)
    const response = await fetch(`${backendUrl}/orders/items`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({
        tableId, restaurantId, ownerId: state.userId,
        lineItems: state.items, type
      })
    })

    setIsLoading(false)
    dispatch({ type: 'reset' })
    history.push({ pathname: `/restaurants/${restaurantId}/tables/${tableId}/order`, state: restaurant })
  }

  const onClickSharable = async () => {
    history.push({ pathname: `add-info`, state: restaurant })
  }

  function renderlistItems(list) {
    return list
      .map(product => (
        // <ListItem>

        <CartItem product={product} />
        // </ListItem>
      ));
  }


  const id = "cart-details-page"
  if (loading)
    return <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <CircularProgress />
    </Box>

  return (

    <Box>

      <AppBar elevation={0} sx={{ backgroundColor: "white", color: "black", height: "10vh" }} position="fixed">
        <Toolbar sx={{ alignItems: "center" }} >

          <Box ml={!matches ? 1 : 'calc(8px + 33vw)'}>
            <IconButton onClick={() => history.goBack()} edge="start" color="inherit" aria-label="menu" sx={{ mr: 0 }}>
              <ArrowBackIcon sx={{ fontSize: 35 }} />
            </IconButton>
          </Box>
          <Box ml={0}
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            display='flex'
          >

          </Box>
        </Toolbar>
      </AppBar>

      <Offset />

      <Box mt={4} display='flex' flexDirection='column' justifyContent='space-between'>
        <Box>
          <Box mb={0} ml={2}>
            <Typography variant="h4" color="inherit" component="h4">
              {t('Comanda')}
            </Typography>
          </Box>
        </Box>

        {(Math.round(getTotalSum(state.items) * 100) / 100).toFixed(2) < minOrderValue &&
          <Box mb={0} ml={2}>
            <Typography style={{color: "#ff0000"}} variant="h6" color="inherit">
              {t('Comanda minima')} {minOrderValue} RON
            </Typography>
          </Box>
        }
        
        <List >

          {renderlistItems(state.items)}
        </List>

        {state.items.length > 0
            ? 
            <Box ml={1} mr={2} sx={{ pb: '100px' }} display='flex' alignItems="center" justifyContent='space-between'  >
              <Box ml={1} fontWeight="fontWeightBold">
                <Typography variant="h4">
                  {t('Total de plata')}
                </Typography>
              </Box>
              <Box mr={1} fontWeight="fontWeightBold">
                <Typography variant="h5">
                  {(Math.round(getTotalSum(state.items) * 100) / 100).toFixed(2)} RON
                </Typography>
              </Box>
            </Box>
            :
            <Box ml={1} mr={2} sx={{ pb: '100px' }} display='flex' alignItems="center" justifyContent='flex-start' flexDirection="row" >

              <Box ml={1} fontWeight="fontWeightBold">
                
                <Typography variant="h5">
                  {t('Cosul este gol. Va rugam sa adaugati produse!')}
                </Typography>
              </Box>
            </Box>
      }

      </Box>

      {state.items.length > 0 &&
        restaurantKey ?
          <Box sx={{ position: 'fixed', bottom: 20, left: !matches ? 10 : 'calc(10px + 33vw)', right: !matches ? 10 : 'calc(10px + 33vw)' }} elevation={3}>
            <LoadingButton loading={isLoading} onClick={onClickSharable} sx={{ fontWeight: "fontWeightBold", minHeight: '60px' }} size="large" variant="contained" fullWidth>
              {t('Continua')}
            </LoadingButton>
          </Box>
          :
          <Box sx={{ position: 'fixed', bottom: 20, left: !matches ? 10 : 'calc(10px + 33vw)', right: !matches ? 10 : 'calc(10px + 33vw)' }} elevation={3}>
            <LoadingButton loading={isLoading} onClick={onClick} sx={{ fontWeight: "fontWeightBold", minHeight: '60px' }} size="large" variant="contained" fullWidth>
              {t('Comanda')}
            </LoadingButton>
          </Box>
        
      }

      {state.items.length === 0 &&
        <Box sx={{ position: 'fixed', bottom: 20, left: !matches ? 10 : 'calc(10px + 33vw)', right: !matches ? 10 : 'calc(10px + 33vw)' }} elevation={3}>
          <LoadingButton loading={isLoading} onClick={() => history.goBack()} sx={{ fontWeight: "fontWeightBold", minHeight: '60px' }} size="large" variant="contained" fullWidth>
            {t('Mergi inapoi la meniu')}
          </LoadingButton>
        </Box>
      }


    </Box>

  )
}
export default CartDetailsPage

