import React, { useRef, createRef, useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom'
import { backendUrl } from "../index";
import Dish from './dish/Dish'
import Box from '@mui/material/Box';
import { Avatar, Typography, Paper, IconButton, ButtonGroup } from '@mui/material';
import useFetch from 'use-http'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
    useHistory
} from "react-router-dom";

import { useCart } from '../context/cart/context'
import Map from './Map/Map'
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import RestaurantInfoDetails from './RestaurantInfoDetails'


const RestaurantInfoPage = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const { restaurantId, restaurantKey, tableId } = useParams()

    const [restaurantSimple, setRestaurantSimple] = useState()
    const { state, dispatch } = useCart()

    useEffect(() => {
        if (state.sessionId) {
            fetch(backendUrl + '/aaaa', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({ tableId, restaurantId, restaurantKey, userId: state.userId, sessionId: state.sessionId, page: window.location.href + "/?userId=" + state.userId})
            })
        }

    }, [state.sessionId])

    const language = state.language

    async function loadRestaurantSimple() {
        const response = await fetch(backendUrl + '/restaurants/' + (restaurantId ? restaurantId : `${restaurantKey}/key`) + "?simple=true" + (language === 'ro' ? '' : `&lang=${language}`))
        if (response.ok) {
            const json = await response.json()
            setRestaurantSimple(json)
        }
        window.scrollTo(0, cart.position)
    }

    const { state: cart } = useCart()

    useEffect(() => {
        loadRestaurantSimple()
    }, [])


    useEffect(() => {
        window.scrollTo(0, cart.position)
    }, [])

    const history = useHistory()

    if (!restaurantSimple) return <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight="100vh"
                                    
                                    >
                                        <CircularProgress />
                                </Box>


    const between = (<Box sx={{ display: 'flex', flexDirection: "column" }}>
        <Box  sx={{ml:3, mt: "20px" }} >
            <Typography  fontWeight="bold" variant="h4" >
                {restaurantSimple.name}
            </Typography>
            <Typography ml="3px" variant="body2" >
                {/* {restaurantSimple.description} */}
            </Typography>
        </Box>
        
    </Box>)

    return (
        <>
            <Fab size="small" onClick={() => history.goBack()} sx={{ position: 'absolute', top: '10px', left: !matches ? '10px' : 'calc(10px + 33vw)', zIndex: 1, backgroundColor: "#fafafa" }} aria-label="add">
                <ArrowBackIcon />
            </Fab>
            {/* <Avatar
                sx={{
                    width: '100%',
                    height: '200px'
                }}
                variant="square"
                src={restaurantSimple.coverUrlImg}>
            </Avatar> */}
            <Map latR={restaurantSimple.latitude} lngR={restaurantSimple.longitude}/>
            <Box mt={0}>
                {between}
            </Box>

            <Box ml={1} sx={{ position: "absolute", top: "210px" }}>
                {/* <Avatar
                    style={{ width: '100px', height: '100px', border: '5px solid white' }}
                    src={restaurantSimple.profileUrlImg}>
                </Avatar> */}
            </Box>
            
            <Box mt={1}>
                <RestaurantInfoDetails 
                    ssid={restaurantSimple.ssid}
                    wifiPassword={restaurantSimple.wifiPassword}
                    address={restaurantSimple.address}
                    program={restaurantSimple.program}
                    email={restaurantSimple.email}
                    phone={restaurantSimple.phone}
                />
            </Box>
        </>
    )
}
export default RestaurantInfoPage