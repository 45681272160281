
   
import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { useCart } from '../context/cart/context'

 
  const root = {
    background: '#eceff1',
    borderRadius: 3,
    border: 0,
    color: 'black',
    height: 58,
    // width: '33vw',
    '&:hover': {
      backgroundColor: '#eceff1', border: 0
    },
    // padding: '0 130px',
  }
  const rootActive = {
    background: '#000',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 58,
    fontWeight: 'bold'  ,
    // width: '34vw',
    '&:hover': {
        backgroundColor: '#000', border: 0
      },
    // padding: '0 230px',
  }


export default function ToggleButtons({tabWidth="50%", activeTab="50%"}) {
    const { state, dispatch } = useCart()

    const { t, i18n } = useTranslation();

    return (
    <Box ml={1} mr={1} mt={3} display="flex" justifyContent="center">
      <ButtonGroup sx={{width:"100%"}} size="large" aria-label="small outlined button group">
        <Button style={{width: state.selected === 'left' ? activeTab : tabWidth}} onClick={() => dispatch({ type: 'change', payload: 'left' })} sx={state.selected === 'left' ? rootActive : root}>{t('Livrare')}</Button>
        <Button style={{width: state.selected === 'center' ? activeTab : tabWidth}} onClick={() => dispatch({ type: 'change', payload: 'center' })}  sx={state.selected === 'center' ? rootActive : root}>{t('Ridica')}</Button>
        {/* <Button style={{width: state.selected === 'right' ? activeTab : tabWidth}} onClick={() => dispatch({ type: 'change', payload: 'right' })} sx={state.selected === 'right' ? rootActive : root}>La Masa</Button> */}
      </ButtonGroup>
    </Box>
  );
}