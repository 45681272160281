import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import LocationOn from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButtons from './ToggleButtons'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 15,
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.common.white, 0.25),
  // },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));



export default function SearchAppBar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box mb={3}  sx={{ flexGrow: 1 }}>
      <AppBar elevation={0}  sx={{backgroundColor: 'white', color: 'black', height: "8vh"}} position="fixed">
           
            <Box display="flex" alignItems="center" ml={!matches ? 1 : 'calc(5px + 33vw)'} mt={2}>  
              <LocationOn /> 
              <Typography ml={"3px"} sx={{fontWeight: "fontWeightBold"}} variant="h6">
                Bucuresti, Romania
              </Typography>
            </Box>
          

      </AppBar>
      <Offset />

      {/* <Box  ml={1} mt={5}>
        <Typography sx={{fontWeight: "fontWeightBold"}} variant="h4">
          Hello Marian
        </Typography>
      </Box> */}
      <Box display="flex" alignItems="center" ml={1} mt={2}>   <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search></Box>

          {/* <Box ml={1}><ToggleButtons/></Box> */}

    </Box>
  );
}
