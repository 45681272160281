import {blue, pink, green} from '@mui/material/colors'
export const themeOptions = {
    typography: {
        fontFamily: ['Raleway'].join(',')
    },
    palette: {
        type: 'light',
        primary: {
            main: '#000000',
        },
        // secondary: {
        //     main: '#00c853',
        // },
        // primary: gray,
        // secondary: green,
        secondary: {
            main:green.A700,
            contrastText: '#fff',

        },
        neutral: {
            main: '#00c853',
            contrastText: '#fff',
          },
    },
    props: {
        MuiAppBar: {
            color: 'default',
        },
    },
    // overrides: {
    //     MuiSwitch: {
    //         root: {
    //             width: 42,
    //             height: 26,
    //             padding: 0,
    //             margin: 8,
    //         },
    //         switchBase: {
    //             padding: 1,
    //             '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
    //                 transform: 'translateX(16px)',
    //                 color: '#fff',
    //                 '& + $track': {
    //                     opacity: 1,
    //                     border: 'none',
    //                 },
    //             },
    //         },
    //         thumb: {
    //             width: 24,
    //             height: 24,
    //         },
    //         track: {
    //             borderRadius: 13,
    //             border: '1px solid #bdbdbd',
    //             backgroundColor: '#fafafa',
    //             opacity: 1,
    //             transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    //         },
    //     },
    // },
};