import './App.css';

import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './theme/variables.css';
import { Grid } from '@mui/material';

import NotFound from './components/NotFound'
import MenuPage from './components/MenuPage'
import DishDetails from './components/dish/DishDetails'
import TableOrderDetailsPage from './components/TableOrderDetailsPage'
import CartDetailsPage from './components/CartDetailsPage'
import RestaurantInfoPage from './components/RestaurantInfoPage'
import RestaurantsPage from './components/RestaurantsPage'
import CollectInfoPage from './components/CollectInfoPage'
import toast, { Toaster } from 'react-hot-toast';

function App() {
  return (
    <>
      <CssBaseline />
      <Toaster/>
      <Grid container>
        <Grid item   md={4} lg={4} xl={4}>
          {/* <Box  sx={{backgroundColor: "#fafafa", width:"100%", height:"100%"}}></Box> */}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Router>
            <Switch>
              <Redirect exact from='/' to='/restaurants'/>
              {/* <Route  path="/dish-details/:id" render={() => <DishDetails />} /> */}
              {/* <Route  path="/restaurant-details/:restaurantId" render={() => <RestaurantInfoPage />} /> */}
             
              {/* <Route  path="/cart" render={() => <CartDetailsPage />}  exact={true} /> */}
              {/* <Route  path="/add-info" render={() => <CollectInfoPage />}  exact={true} /> */}


              <Route  path="/restaurants/:restaurantId/tables/:tableId/cart" render={() => <CartDetailsPage />} exact={true} />
              <Route  path="/restaurants/:restaurantId/tables/:tableId/order" render={() => <TableOrderDetailsPage />} exact={true} />
              <Route  path="/restaurants/:restaurantId/tables/:tableId/dish-details/:id" render={() => <DishDetails />} />
              <Route  path="/restaurants/:restaurantId/tables/:tableId/info" render={() => <RestaurantInfoPage />} />
              <Route  path="/restaurants/:restaurantId/tables/:tableId" render={() => <MenuPage />} />

              <Route  path="/restaurants" render={() => <RestaurantsPage />} />

              <Route  path="/:restaurantKey/dish-details/:id" render={() => <DishDetails />} />
              <Route  path="/:restaurantKey/info" render={() => <RestaurantInfoPage />} exact={true} />
              <Route  path="/:restaurantKey/cart" render={() => <CartDetailsPage />} exact={true} />
              <Route  path="/:restaurantKey/add-info" render={() => <CollectInfoPage />} exact={true} />
              <Route  path="/:restaurantKey/orders/:orderId" render={() => <TableOrderDetailsPage />} exact={true} />
              <Route  path="/:restaurantKey" render={() => <MenuPage isShareable/>} />

              <Route component={NotFound} />
            </Switch>
          </Router>
        </Grid>
        <Grid item   lg={4} xl={4}>
          {/* <Box  sx={{backgroundColor: "#fafafa", width:"100%", height:"100%"}}></Box> */}
          </Grid>
      </Grid>
    </>
  );
}


export default App;
