import * as React from 'react'
import featureReducer from './reducer'
import {initialState} from './initialState'

const FeatureContext = React.createContext()

function FeatureProvider({children}) {
  const [state, dispatch] = React.useReducer(featureReducer, initialState)
  const value = {state, dispatch}
  return <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>
}
function useFeature() {
  const context = React.useContext(FeatureContext)
  if (context === undefined) {
    throw new Error('useFeature must be used within a Provider')
  }
  return context
}
const MODULE_ORDER = "MODULE_ORDER"
const COLOR = "COLOR"
const INFO = "INFO"
const LANGUAGE = "LANGUAGE"
const NON_RESTAURANT = "NON_RESTAURANT"
const CASH_DELIVERY_ONLY = "CASH_DELIVERY_ONLY"
const DELIVERY_RANGE = "DELIVERY_RANGE"
const REPLACE_RECOMANDED_TEXT = "REPLACE_RECOMANDED_TEXT"
const MIN_ORDER_VALUE = "MIN_ORDER_VALUE"
const BANNER = "BANNER"
const NOTIFICATIONS = "NOTIFICATIONS"
export {FeatureProvider, useFeature, LANGUAGE, MODULE_ORDER, COLOR, INFO, NON_RESTAURANT, CASH_DELIVERY_ONLY, DELIVERY_RANGE, REPLACE_RECOMANDED_TEXT, MIN_ORDER_VALUE, BANNER, NOTIFICATIONS}
