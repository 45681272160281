import React, { useState, useRef, useReducer, useEffect } from 'react';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import MyAutoComplete from '../components/Map/Autocomplete';
import TimePicker from '@mui/lab/TimePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import * as Yup from 'yup';
import ToggleButtons from './ToggleButtons'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Map from './Map/Map'
import { useCart } from '../context/cart/context'
import useFetch from 'use-http'
import DateTimePicker from '@mui/lab/DateTimePicker';

import { backendUrl } from '../index'
import {
  useHistory,
  useLocation, useParams
} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GoogleMapReact from 'google-map-react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled as styledmui } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import CartItem from './CartItem';
import cceva from '../App.css'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loading from './Loading'
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import WifiIcon from '@mui/icons-material/Wifi';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CopyTextButton from './CopyTextButton';
import { useTranslation } from 'react-i18next';
import { useFeature, CASH_DELIVERY_ONLY, DELIVERY_RANGE, MIN_ORDER_VALUE } from '../context/feature/context'
import styled from 'styled-components';
import Marker from './Map/Marker';

const styles = require('./../GoogleMapStyles.json')

const Wrapper = styled.main`
  width: 100%;
  height: 200px
`;

function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
}


function getDistanceBetweenPoints(lat1, lng1, lat2, lng2) {
  // The radius of the planet earth in meters
  let R = 6378137;
  let dLat = degreesToRadians(lat2 - lat1);
  let dLong = degreesToRadians(lng2 - lng1);
  let a = Math.sin(dLat / 2)
    *
    Math.sin(dLat / 2)
    +
    Math.cos(degreesToRadians(lat1))
    *
    Math.cos(degreesToRadians(lat1))
    *
    Math.sin(dLong / 2)
    *
    Math.sin(dLong / 2);

  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let distance = R * c;

  return distance;
}

const reducer = (accumulator, currentValue) => accumulator + currentValue;

export function getTotalSum(list) {
  return list
    .map(p => p.quantity * p.price)
    .reduce(reducer, 0)
}

const Offset = styledmui('div')(({ theme }) => theme.mixins.toolbar);

const CollectInfoPage = () => {
  const { restaurantKey, restaurantId, tableId } = useParams()
  const history = useHistory()
  const [first, setFirst] = useState(true)
  const [value, setValue] = React.useState(Date.now());

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setCurrentLocation()
  }, [])
  const restaurant = useLocation().state
  const { state, dispatch } = useCart()
  const [isLoading, setIsLoading] = useState(false)
  const [program, setProgram] = useState(false)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (state.sessionId) {
      fetch(backendUrl + '/aaaa', {
        method: 'POST', headers: {
          'Content-Type': 'application/json'
        }, body: JSON.stringify({ tableId, restaurantId, restaurantKey, userId: state.userId, sessionId: state.sessionId, page: window.location.href + "/?userId=" + state.userId + (state.selected === "left" ? "/delivery" : "/pickup")  })
      })
    }

  }, [state.sessionId, state.selected])

  const { state: featureState, dispatch: featureDispatch } = useFeature()

  const cashDeliveryOnly = featureState?.features?.hasOwnProperty(CASH_DELIVERY_ONLY)
  const deliveryRange = featureState?.features?.hasOwnProperty(DELIVERY_RANGE) ? parseInt(featureState.features[DELIVERY_RANGE]) : 4
  const minOrderValue = (!!restaurantKey && featureState.features.hasOwnProperty(MIN_ORDER_VALUE)) ? featureState.features[MIN_ORDER_VALUE] : 0

  const [mapApiLoaded, setmapApiLoaded] = useState(false)
  const [mapInstance, setmapInstance] = useState()
  const [mapApi, setmapApi] = useState({})
  const [places, setplaces] = useState([])
  const [center, setcenter] = useState(state.userDetails.lat ? [state.userDetails.lat, state.userDetails.lng] : [parseFloat(restaurant.latitude), parseFloat(restaurant.longitude)])
  const [zoom, setzoom] = useState(10)
  const [address, setaddress] = useState(state.userDetails.address)
  const [draggable, setdraggable] = useState(true)
  const [lat, setlat] = useState(state.userDetails.lat)
  const [lng, setlng] = useState(state.userDetails.lng)


  const RegisterSchema = Yup.object().shape({
    phoneNumber: Yup.string().min(10, 'Numarul de telefon este prea scurt!').required('Numarul de telefon este obligatoriu!'),
    email: Yup.string().optional().email('Introduceti un email valid!'),
    firstName: Yup.string().required('Numele si prenumele sunt obligatorii!'),
    // lastName: Yup.string().optional().min(1, 'Numele este prea scurt!').max(50, 'Numele este prea lung!').required('Numele este obligatoriu!'),
    address: Yup.string().required('Adresa este obligatorie!'),//validare minim 6 caractere
    addressdetalis: Yup.string()//validare minim 6 caractere
  });
  const RegisterSchemaPickup = Yup.object().shape({
    phoneNumber: Yup.string().min(10, 'Numarul de telefon este prea scurt!').required('Numarul de telefon este obligatoriu!'),
    email: Yup.string().optional().email('Introduceti un email valid!'),
    firstName: Yup.string().required('Numele si prenumele sunt obligatorii!'),
  });

  const distance = getDistanceBetweenPoints(lat, lng, restaurant.latitude, restaurant.longitude) / 1000
  
  function getTimestamp (timestamp) {
    const pad = (n,s=2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
    const d = new Date(timestamp);
    
    return `${pad(d.getFullYear(),4)}-${pad(d.getMonth()+1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
  }
  const formik = useFormik({
    initialValues: { ...state.userDetails },
    validationSchema: state.selected === 'center' ? RegisterSchemaPickup : RegisterSchema,
    onSubmit: async (values) => {

      if (state.selected === "left") {
        if (distance > deliveryRange) {
          setErrors({ ...errors, address: 'Ne pare rau, nu te afli in aria de acoperire a restaurantului!' })
          return
        }
      }



      let type = "ONLINE_DELIVERY"

      if (state.selected === 'center') {
        type = 'ONLINE_PICKUP'
      }

      setIsLoading(true)
      const response = await fetch(`${backendUrl}/orders/new-order`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          restaurantKey, ownerId: state.userId,
          lineItems: state.items, type, deliveryInfo:  { ...values, address: address + " \nDetalii: " + values.addressdetalis +  " \nProgramata pentru: " + getTimestamp(value)} 
        })
      })

      const json = await response.json()
      dispatch({ type: 'reset' })
      dispatch({ type: 'setOrderId', payload: json.id });
      dispatch({ type: 'addUserDetails', payload: { ...values, lat, lng } })
      setIsLoading(false)

      if (state.sessionId) {
        fetch(backendUrl + '/aaaa', {
          method: 'POST', headers: {
            'Content-Type': 'application/json'
          }, body: JSON.stringify({ tableId, restaurantId, restaurantKey, userId: state.userId, sessionId: state.sessionId, page: window.location.href + "/?userId=" + state.userId + (state.selected === "left" ? "/delivery" : "/pickup") + "/submit" })
        })
      }
      history.push({ pathname: `orders/${json.id}`, state: restaurant })
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, setErrors, setFieldError } = formik;

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setdraggable(false)
    setlat(mouse.lat)
    setlng(mouse.lng)
  }
  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setdraggable(true);
    _generateAddress();
  }

  const _onChange = ({ center, zoom }) => {
    setcenter(center)
    setzoom(zoom)
    _generateAddress()

  }

  const _onClick = (value) => {
    setlat(value.lat)
    setlng(value.lng)
  }

  const apiHasLoaded = async (map, maps) => {
    setmapApiLoaded(true)
    setmapInstance(map)
    setmapApi(maps)



  };

  useEffect(() => { _generateAddress() }, [mapApi])
  const _generateAddress = () => {
    setFirst(true)
    if (!lat)
      return
    if (distance > deliveryRange) {
      setErrors({ ...errors, address: 'Ne pare rau, nu te afli in aria de acoperire a restaurantului!' })
      // setFieldError("address", 'Ne pare rau, nu te afli in aria de acoperire a restaurantului!')

    }
    if (!mapApiLoaded)
      return

    if (!mapApi)
      return
    let geocoder
    try {
      geocoder = new mapApi.Geocoder;
      if (!geocoder)
        return
      geocoder.geocode({ 'location': { lat: lat, lng: lng } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            setzoom(15);
            setaddress(results[0].formatted_address);
            setFieldValue("address", results[0].formatted_address, true)
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }

      });

    } catch (e) {
      console.log(e)
    }
  }


  function setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setzoom(17)
        setcenter([position.coords.latitude, position.coords.longitude])
        setlat(position.coords.latitude)
        setlng(position.coords.longitude)
        _generateAddress()
      });

    }
  }
  const addPlace = (place) => {
    setplaces([place])
    setzoom(15)
    setcenter([place.geometry.location.lat(), place.geometry.location.lng()])
    setlat(place.geometry.location.lat())
    setlng(place.geometry.location.lng())
    _generateAddress()
  };

  useEffect(() => {
    if (distance > deliveryRange) {
      setErrors({ ...errors, address: 'Ne pare rau, nu te afli in aria de acoperire a restaurantului!' })
      // setFieldError("address", 'Ne pare rau, nu te afli in aria de acoperire a restaurantului!')

    }
  }, [errors, distance])
  return (

    <Box>

      <AppBar elevation={0} sx={{ backgroundColor: "white", color: "black", height: "10vh" }} position="fixed">
        <Toolbar sx={{ alignItems: "center" }} >

          <Box ml={!matches ? 1 : 'calc(8px + 33vw)'}>
            <IconButton onClick={() => history.goBack()} edge="start" color="inherit" aria-label="menu" sx={{ mr: 0 }}>
              <ArrowBackIcon sx={{ fontSize: 35 }} />
            </IconButton>
          </Box>
          <Box ml={2}
            justifyContent="center"
            alignItems="center"
            minHeight="10vh"
            display='flex'
          >

          </Box>
        </Toolbar>
      </AppBar>

      <Offset />

      <Box mt={4} display='flex' flexDirection='column' justifyContent='space-between'>
        <Box>
          <Box mb={0} ml={2}>  <Typography variant="h4" color="inherit" component="h4">
            {t('Detalii Comanda')}
          </Typography></Box>
        </Box>
      </Box>

      <Box mb={3} mt={3} >
        <ToggleButtons />
      </Box>

      {state.selected === "left" ?
        <Box ml={1} mr={1}>
          <Box ml={1} mr={1} mt={2}>
            <Typography variant="h5" color="inherit" component="h6">
              {t('Te rugam sa adaugi detalii pentru livrare!')}
            </Typography>
          </Box>
          <Box ml={1} mr={1} mt={1} display='flex' flexDirection='column' justifyContent='space-between'>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    label={t('Numar telefon')}
                    {...getFieldProps('phoneNumber')}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                  <TextField
                    required
                    sx={{ backgroundColor: "#fff" }}
                    fullWidth
                    variant="standard"
                    label={t('Nume si prenume')}
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />

                  {/* <TextField
                  fullWidth
                  label="Nume"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                /> */}
                  <TextField
                    fullWidth
                    variant="standard"
                    type="email"
                    label={t('Email')}
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />




                  {mapApiLoaded && (
                    <div>
                      <MyAutoComplete first={first} setFirst={setFirst} distance={distance} address={address} addplace={addPlace}
                        otherProps={getFieldProps('address')}
                        error={Boolean(errors.address)}
                        helperText={errors.address} />

                    </div>


                  )}
                  <Box mt={1}>

                    <Wrapper>

                      <GoogleMapReact
                        onDrag={(e) => {  setlat(e.center.lat); setlng(e.center.lng) }}
                        center={center}
                        defaultZoom={15}
                        draggable={draggable}
                        onChange={_onChange}
                        onChildMouseDown={onMarkerInteraction}
                        onChildMouseUp={onMarkerInteractionMouseUp}
                        onChildMouseMove={onMarkerInteraction}
                        onChildClick={() => console.log('child click')}
                        onClick={_onClick}
                        bootstrapURLKeys={{
                          key: 'AIzaSyCqQdf2lCuY7g23ax7iEr93AeiA0hOF6zw',
                          libraries: ['places', 'geometry'],
                        }}
                        options={{
                          gestureHandling: 'greedy',
                          // panControl: false,
                          // mapTypeControl: false,
                          // scrollwheel: false,
                          styles: styles,
                          disableDefaultUI: true,
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => { console.log("ggggg"); apiHasLoaded(map, maps); }}
                      >

                        {/* <MarkerRestaurant
          lat={parseFloat(props.latR)}
          lng={parseFloat(props.lngR)}
      />  */}
                        <Marker
                          text={address}
                          lat={lat}
                          lng={lng}
                        />


                      </GoogleMapReact>



                    </Wrapper >
                    {/* <MyGoogleMap setAddressValue={setAddressValue} latR={restaurant.latitude} lngR={restaurant.longitude} /> */}
                    {/* <Map latR={restaurant.latitude} lngR={restaurant.longitude}/> */}
                  </Box>
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography fontWeight="fontWeightBold" variant="h5" mt={3}>
                      Ora livrarii:
</Typography>
                    <Box onClick={() => setProgram(false)} display="flex" alignItems="center" justifyContent="center" sx={{ border: `1px solid ${program ? "#E0E0E0" : ""} `, width: "90vw", height: "7vh" }} mt={1}>
                      <Typography fontWeight={!program ? "bold" : ""} variant="h6" >
                        Cat mai curand
</Typography>
                    </Box>

                    <Box mb={2} onClick={() => setProgram(true)} display="flex" alignItems="center" justifyContent="center" sx={{ border: `1px solid ${!program ? "#E0E0E0" : ""}`, width: "90vw", height: "7vh" }} mt={1}>
                      <Typography fontWeight={program ? "bold" : ""} variant="h6" >
                        Programeaza
</Typography>
                    </Box>
                    <Stack spacing={2}>
                      {program && <DateTimePicker
                        value={value}
                        onChange={handleChange}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                      />}

                    </Stack>

                  </Box>
                  <Box mt={3}>
                    <TextField
                      fullWidth
                      variant="standard"
                      // rows={3}
                      autoComplete="off"
                      multiline
                      value=''
                      type='text'
                      label={t('Detalii adresa')}
                      {...getFieldProps('addressdetalis')}
                      error={Boolean(touched.addressdetalis && errors.addressdetalis)}
                      helperText={touched.addressdetalis && errors.addressdetalis}
                    />
                  </Box>

                </Stack>

              </Form>
            </FormikProvider>



          </Box>
        </Box>
        :
        <Box>
          <Box ml={2} mr={2} mt={2}>
            <Typography variant="h6" color="inherit" component="h6">
              {t('Comanda va fi preluata de la locatia restaurantului')}
            </Typography>
          </Box>
          <Box mt={1} ml={2} mr={2}>
            <Stack mb={3} spacing={2}>
              <TextField
                required
                fullWidth
                variant="standard"
                label={t('Numar telefon')}
                {...getFieldProps('phoneNumber')}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
              />
              <TextField
                required
                sx={{ backgroundColor: "#fff" }}
                fullWidth
                variant="standard"
                label={t('Nume si prenume')}
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              {/* <TextField
                  fullWidth
                  label="Nume"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                /> */}
              <TextField
                fullWidth
                variant="standard"
                type="email"
                label={t('Email')}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

            </Stack>
            {/* <Box display="flex" alignItems="center">
              <Box mt="4px" mr={1}>
                <LocationOnOutlinedIcon />
              </Box>
              <Typography variant="h5" >
                {t('Adresa')}
              </Typography>
            </Box> */}

            <Box mb={3}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start">
                <Box mt="4px" mr={1}>
                  <LocationOnOutlinedIcon sx={{ fontSize: 35 }} />
                </Box>
                <Box ml={1} mr={1}>
                  <Typography>
                    <Link sx={{ color: "blue" }} underline="none" href={`https://www.google.com/maps/place/${restaurant.address}`}>{restaurant.address}</Link>
                  </Typography>
                </Box>
              </Box>
              <Box mr={1}>
                <CopyTextButton text={restaurant.address} />
              </Box>
            </Box>

            <Box mt={1}>
              <Map latR={restaurant.latitude} lngR={restaurant.longitude} />
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography fontWeight="fontWeightBold" variant="h5" mt={3}>
                      Ora preluarii:
</Typography>
                    <Box onClick={() => setProgram(false)} display="flex" alignItems="center" justifyContent="center" sx={{ border: `1px solid ${program ? "#E0E0E0" : ""} `, width: "90vw", height: "7vh" }} mt={1}>
                      <Typography fontWeight={!program ? "bold" : ""} variant="h6" >
                        Cat mai curand
</Typography>
                    </Box>

                    <Box mb={2} onClick={() => setProgram(true)} display="flex" alignItems="center" justifyContent="center" sx={{ border: `1px solid ${!program ? "#E0E0E0" : ""}`, width: "90vw", height: "7vh" }} mt={1}>
                      <Typography fontWeight={program ? "bold" : ""} variant="h6" >
                        Programeaza
</Typography>
                    </Box>
                    <Stack spacing={2}>
                      {program && <DateTimePicker
                        value={value}
                        onChange={handleChange}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                      />}

                    </Stack>

                  </Box>


          </Box>

        </Box>
      }

      {(Math.round(getTotalSum(state.items) * 100) / 100).toFixed(2) < minOrderValue &&
        <Box>
          <Box ml={1} mr={1} mt={3} display='flex' alignItems="center" justifyContent='space-between'  >
            <Box ml={1} fontWeight="fontWeightBold">
              <Typography variant="h6">
                {t('Total produse')}
              </Typography>
            </Box>
            <Box mr={1} fontWeight="fontWeightBold">
              <Typography variant="body1">
                {(Math.round(getTotalSum(state.items) * 100) / 100).toFixed(2)} RON
              </Typography>
            </Box>

          </Box>

          <Box ml={1} mr={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box ml={1}>
              <Typography style={{ color: "#ff0000" }} variant="h6" color="inherit">
                {t('Comanda minima')}
              </Typography>
            </Box>

            <Box mr={1}>
              <Typography style={{ color: "#ff0000" }} variant="h6" color="inherit">
                {minOrderValue} RON
              </Typography>
            </Box>
          </Box>
        </Box>
      }

      <Box ml={1} mr={1} mt={3} display='flex' alignItems="center" justifyContent='space-between'  >
        <Box ml={1} fontWeight="fontWeightBold">
          <Typography variant="h4">
            {t('Total de plata')}
          </Typography>
        </Box>

        <Box mr={1} fontWeight="fontWeightBold">
          <Typography variant="h5">
            {Math.max(minOrderValue, (Math.round(getTotalSum(state.items) * 100) / 100).toFixed(2))} RON
          </Typography>
        </Box>

      </Box>

      {cashDeliveryOnly && state.selected === 'left' &&
        <Box ml={2} mr={2} mt={2} display='flex' alignItems="center" alignContent="center" >
          <InfoOutlined sx={{ color: "#ff0000" }} />
          <Typography variant='body1' ml={1} mt="1px">
            {t('Atentie! Plata cu cardul nu este disponibila pentru acest restaurant!')}
          </Typography>

        </Box>}

      <Box sx={{ pb: '190px' }}  >
      </Box>
      <Box sx={{ position: 'fixed', bottom: 20, left: !matches ? 10 : 'calc(10px + 33vw)', right: !matches ? 10 : 'calc(10px + 33vw)' }} elevation={3}>
        <LoadingButton loading={isLoading} onClick={handleSubmit} sx={{ fontWeight: "fontWeightBold", minHeight: '60px' }} size="large" variant="contained" fullWidth>
          {t('Comanda')}
        </LoadingButton>
      </Box>



    </Box>

  )
}
export default CollectInfoPage

