import React, { useRef, createRef, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


export default function Loading({minHeight = "100vh"}) {
    return <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight={minHeight}
>
    <CircularProgress />
</Box>
}