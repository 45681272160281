import classes from './marker.css'
import GoogleMapReact from 'google-map-react';

const Marker = props => {
    return <>
      <div className="pin"></div>
      <div className="pulse"></div>
    </>
  }
  
 export default function Map({latR, lngR, latU, lngU}){
  const defaultProps = {
    center: {
         
      lat: parseFloat(latR) || 0,
      lng: parseFloat(lngR) || 0
    },
    zoom: 15
  };

  const styles = require('./../../GoogleMapStyles.json')
 
  return (
    // Important! Always set the container height explicitly
    <div style={{  width: '100%',
    height: '200px' }}>
      <GoogleMapReact
    //   onGoogleApiLoaded={({map, maps}) => console.log(map, maps)}
    //   yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: "AIzaSyCqQdf2lCuY7g23ax7iEr93AeiA0hOF6zw" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
            styles: styles,
            disableDefaultUI: true,
          }}
      >
        <Marker
          lat={latR}
          lng={lngR}
        />

        {latU && <Marker
          lat={latU}
          lng={lngU}
        />}
      </GoogleMapReact>
    </div>
  );
}

  