import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Paper, Box, Avatar, Icon, Button } from '@mui/material';
import Grid from '@mui/material/Grid'
import Tag from './Tag'
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';

import blue from '@mui/material/colors/blue';
import Tooltip from '@mui/material/Tooltip';

import { useFeature, MODULE_ORDER, COLOR, BANNER } from '../context/feature/context'


import {
    Chip
} from '@mui/material'
import { useCart } from '../context/cart/context'
import {
    useHistory
} from "react-router-dom";

import Fab from '@mui/material/Fab';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import AddIcon from '@mui/icons-material/Add';

import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        '&::after': {
            position: 'absolute',
            top: '101px',
            left: '101px',
            width: '100%',
            height: '100%',
        },
    },

}));


export default function Banner() {

    const { state, dispatch } = useCart()
    const history = useHistory()
    const { state: featureState } = useFeature()
    const color = featureState.features.hasOwnProperty(COLOR) ? featureState.features[COLOR] : '#000'
    const banner = featureState.features.hasOwnProperty(BANNER) ? JSON.parse(featureState.features[BANNER]) : null

    const [open, setOpen] = React.useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false)
        }, 8000);
        return () => clearTimeout(timer);
    }, [open]);

    // newTag, popularTag
    function handleOnClick() {
        window.open(banner.bannerGoToLink)
    }

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        banner &&
        <Box
            sx={{
                borderRadius: 0
            }}

            onClick={handleOnClick}

        >
            <Box display="flex"  justifyContent="center"
                
                sx={{
                    borderRadius: 0,
                }}
            >

                <Box  mt={1}> 
                        <Chip 
                                    size="small"
                                    variant="outlined"
                                    clickable={true}
                                    style={{
                                        position: 'absolute',
                                        // bottom: '9%',
                                        left: !matches ? "5%" : 'calc(5% + 31vw)',
                                        zIndex: 10,

                                        backgroundColor: red[700],
                                        border: '0px',
                                        marginRight: '5px',
                                        fontWeight: "bold",
                                        color: '#fff',
                                        fontSize: '10px'
                                    }}
                                    key={"new"}
                                    label={"Sponsorizat"}
                                    />
                        </Box>

                <Box mt={"113px"} >
                    <Button
                        // size="small"
                        variant="outlined"
                        clickable={true}
                        style={{

                            position: 'absolute',
                            // top: '3%',
                            right: '4%',
                            // bottom: '8vh',
                            zIndex: 10,

                            backgroundColor: "#fff",
                            border: '0px',
                            marginRight: !matches ? "5px" : 'calc(5px + 31vw)',
                            fontWeight: "bold",
                            color: '#000',
                            // width: !matches ? "95vw" : "31vw",
                        }}

                    >
                        <Typography variant="body2" sx={{ fontWeight: "fontWeightBold", fontSize: 12 }}>{banner.bannerButton}</Typography>
                    </Button>
                </Box>

                <Box mt={"55px"} sx={{
                    position: "absolute",
                    // bottom: "13%",
                    // opacity: 0.1,
                    // backgroundColor:"#000",
                    zIndex:1000,
                    // width: "95vw", /* Full width */
                    // height: "17vh",
                    // padding: "20px"
                    // width: !matches ? "95vw" : "31vw",
                }}>
                       <Typography  align="left" variant="h4" sx={{ color: "#fff"  ,
                    fontWeight: "bold"
                }}>
                           {banner.bannerText} 
                       </Typography>
                </Box>


               <Box >
               <Avatar
                    sx={{
                        position: 'relative',
                        filter: "brightness(70%) ",
                        borderRadius: 5,
                        zIndex: 0,
                        width: !matches ? "95vw" : "31vw",
                        height: "150px"
                    }}
                    // src={imgUrl.substring(0, 49) + "h_300" + imgUrl.substring(49)}
                    // src={imgUrl + "?tr=h-300"}
                    src={banner.bannerImg}


                />
               </Box>

            </Box>
        </Box>
    );
}
