import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Paper, Box, Avatar, Icon } from '@mui/material';
import Grid from '@mui/material/Grid'
import Tag from '../Tag'
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import blue from '@mui/material/colors/blue';
import Tooltip from '@mui/material/Tooltip';
import Card from '../Card'
import { useFeature, MODULE_ORDER, COLOR } from '../../context/feature/context'
import DishNoImage from './DishNoImage';
import { useTranslation } from 'react-i18next';

import {
    Chip
} from '@mui/material'
import { useCart } from '../../context/cart/context'
import {
    useHistory
} from "react-router-dom";

import Fab from '@mui/material/Fab';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import AddIcon from '@mui/icons-material/Add';

import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        '&::after': {
            position: 'absolute',
            top: '101px',
            left: '101px',
            width: '100%',
            height: '100%',
        },
    },

}));


export default function Dish({hasStories, setStory, product, restaurantId, restaurantKey, tableId, tooltip , restaurant}) {
    const { state, dispatch } = useCart()
    const history = useHistory()
    const { state: featureState } = useFeature()
    const color = featureState.features.hasOwnProperty(COLOR) ? featureState.features[COLOR] : '#000'
    const [open, setOpen] = React.useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false)
        }, 8000);
        return () => clearTimeout(timer);
    }, [open]);
    const { id, name, description, imgUrl, price, priceDetails, discount, tags, available } = product
    // console.log("discount")
    // console.log((discount && discount !== 0))

    const { t, i18n } = useTranslation();
    // newTag, popularTag
    const newTag = tags.map(tag => tag.value).includes("New")
    const popularTag = tags.map(tag => tag.value).includes("Popular")
    const deliveryOnlyTag = tags.map(tag => tag.value).includes("DeliveryOnly")
    const bigPost = tags.map(tag => tag.value).includes("BigPost")
    const isAvailable = available || available == undefined
    const hasOrderModule = featureState.features.hasOwnProperty(MODULE_ORDER)
    function handleOnClick() {
        if (isAvailable) {
            dispatch({ type: 'setPosition', payload: window.pageYOffset });
            history.push({ pathname: (restaurantKey ? `/${restaurantKey}` : `/restaurants/${restaurantId}/tables/${tableId}`) + `/dish-details/${id}`, state: { product, hasOrderModule } });
        }
    }

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    console.log(imgUrl)
    // https://ik.imagekit.io/lprqlslc6bt/cloudinary-images/pn6dqajidofigjpiop1u.png
    return  bigPost ? 
                <Card restaurantId={restaurantId} restaurantKey={restaurantKey} tableId={tableId} handleClick={handleOnClick} hasStories={hasStories} setStory={setStory} restaurant={restaurant} product={product}></Card>
                : (!imgUrl || imgUrl === 'http://res.cloudinary.com/daqirrhrb/image/upload/v1634771923/pn6dqajidofigjpiop1u.png' || imgUrl === 'https://ik.imagekit.io/lprqlslc6bt/cloudinary-images/pn6dqajidofigjpiop1u.png') ? <DishNoImage product={product} restaurantId={restaurantId} restaurantKey={restaurantKey} tableId={tableId}/> 
                :
    <Box

        sx={{
            borderRadius: 0
        }}

        onClick={handleOnClick}

    >
        <Paper
            elevation={0}
            sx={{
                borderRadius: 0,
                filter: "opacity(" + (isAvailable ? 100 : 50) + "%)"
            }}
        >
            <Grid container>
                <Grid item xs={8}>
                    <Box ml={1} mt={2} mr={2}>
                        <Box display="flex" >
                            <Typography sx={{
                                fontWeight: "fontWeightBold", width: "100%",
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }} variant="h6">
                                {name}
                            </Typography>
                        </Box>

                        <Box sx={{
                            width: "100%",
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }} mt={0}>
                            <Typography

                                variant="subtitle" color="textSecondary">
                                {description}
                            </Typography>
                        </Box>

                        <Box display="flex">
                            <Box mr={1}>
                                {(!discount || discount === 0) && <Typography sx={{ fontWeight: "fontWeightBold" }} variant="h6">
                                    {price} RON
                                    </Typography>}

                                {(!!discount && discount !== 0) &&
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Typography mr={1} sx={{ color: "#bbbbbb", fontWeight: "fontWeightBold", textDecorationLine: 'line-through' }} variant="h6">
                                            {price} RON
                                        </Typography>

                                        <Typography mr={1} sx={{ fontWeight: "fontWeightBold", color: 'red' }} variant="h6">
                                            {(100 - discount) * price / 100} RON
                                        </Typography>

                                    </Box>
                                }

                            </Box>
                        </Box>

                        <Box mt={0} mb={0}>
                            {tags.filter(tag => tag.value !== "New")
                                .filter(tag => tag.value !== "Recomandate")
                                .filter(tag => tag.value !== "Story")
                                .filter(tag => tag.value !== "Popular")
                                .filter(tag => tag.value !== "BigPost")
                                .filter(tag => tag.value !== "DeliveryOnly")
                                .map(tag => {
                                    if (tag) {
                                        return (
                                            <Tag key={tag.id} tag={tag.value} color={tag.color} />
                                        )
                                    }
                                })}



                            {popularTag && <Chip
                                size="small"
                                variant="outlined"
                                clickable={true}
                                style={{
                                    backgroundColor: green[700],
                                    border: '0px',
                                    marginRight: '5px',
                                    fontWeight: "bold",
                                    color: '#fff',
                                    fontSize: '10px'
                                }}
                                key={"tag"}
                                label={"Popular"}
                            // onClick={onTagClick}
                            />}
                            {deliveryOnlyTag && <Chip
                                size="small"
                                variant="outlined"
                                clickable={true}
                                style={{

                                    backgroundColor: blue[600],
                                    border: '0px',
                                    marginRight: '5px',
                                    fontWeight: "bold",
                                    color: '#fff',
                                    fontSize: '10px'
                                }}
                                key={"tag"}
                                label={"Delivery Only"}
                            // onClick={onTagClick}
                            />}
                            {newTag && <Chip
                                size="small"
                                variant="outlined"
                                clickable={true}
                                style={{
                                    backgroundColor: red[700],
                                    border: '0px',
                                    marginRight: '5px',
                                    fontWeight: "bold",
                                    color: '#fff',
                                    fontSize: '10px'
                                }}
                                key={"new"}
                                label={"New"}
                            // onClick={onTagClick}
                            />}

                        </Box>


                        {!isAvailable && <Chip
                            size="small"
                            variant="outlined"
                            clickable={true}
                            style={{ backgroundColor: "#E8E8E8", border: '0px', marginRight: '5px', fontWeight: "bold", color: '#000', fontSize: '10px' }}
                            key={"Indisponibil"}
                            label={t('Indisponibil')}
                        />}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box mr={2} mt={2} mb={2} >
                        {tooltip ? <Tooltip placement="bottom" arrow open={open && tooltip} title="Apasa pentru mai multe detalii">
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={

                                    (featureState.features.hasOwnProperty(MODULE_ORDER)) ?
                                        <Fab
                                            disabled={!isAvailable}
                                            size="small" sx={{ '&:hover': { backgroundColor: '#fff' }, backgroundColor: '#fff', color, zIndex: 0, position: "relative", top: "-5px", right: "10px" }} color="secondary" aria-label="add">
                                            <AddIcon />
                                        </Fab> : null
                                }
                            >
                                <Avatar
                                    sx={{
                                        position: 'relative',

                                        borderRadius: 5,
                                        zIndex: 0,
                                        width: !matches ? "30vw" : "10vw",
                                        height: !matches ? "40vw" : "13vw"
                                    }}
                                    src={imgUrl + "?tr=h-300"}

                                />

                            </StyledBadge>
                        </Tooltip> :  <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={

                                    (featureState.features.hasOwnProperty(MODULE_ORDER)) ?
                                        <Fab
                                            disabled={!isAvailable}
                                            size="small" sx={{ '&:hover': { backgroundColor: '#fff' }, backgroundColor: '#fff', color, zIndex: 0, position: "relative", top: "-5px", right: "10px" }} color="secondary" aria-label="add">
                                            <AddIcon />
                                        </Fab> : null
                                }
                            >
                                <Avatar
                                    sx={{
                                        position: 'relative',

                                        borderRadius: 5,
                                        zIndex: 0,
                                        width: !matches ? "30vw" : "10vw",
                                        height: !matches ? "40vw" : "13vw"
                                    }}
                                    src={imgUrl + "?tr=h-300"}

                                />

                            </StyledBadge>}
                           
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    </Box>

}
