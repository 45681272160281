import {
    Chip
  } from '@mui/material'
  import green from '@mui/material/colors/green';
  import red from '@mui/material/colors/red';
  import pink from '@mui/material/colors/pink';
  import purple from '@mui/material/colors/purple';
  import deepPurple from '@mui/material/colors/deepPurple';
  import lightBlue from '@mui/material/colors/lightBlue';
  import lightGreen from '@mui/material/colors/lightGreen'
  import yellow from '@mui/material/colors/yellow'
  import amber from '@mui/material/colors/amber'
  import cyan from '@mui/material/colors/cyan'
  import blue from '@mui/material/colors/blue'
  import indigo from '@mui/material/colors/indigo'
  import teal from '@mui/material/colors/teal'
  import orange from '@mui/material/colors/orange'
  import deepOrange from '@mui/material/colors/deepOrange'


  import { useHistory } from 'react-router-dom'
  
  const colors = [ green, yellow, pink, purple, deepPurple, lightBlue, lightGreen, cyan, amber, blue, red, indigo, teal, orange, deepOrange]
  
  export default function Tag({tag, color}) {
    const history = useHistory()
    const onTagClick = () => {
        const tag = '🍕'
    }
  
    return (
      <Chip
           size="small"
          variant="outlined"
          clickable={true}
          style={{backgroundColor: colors[color][100], border:'0px', marginRight: '5px', fontWeight: "bold", fontSize:  '20px'}}
          key={tag}
          label={tag}
          onClick={onTagClick}
      />
      )
  
  }
  