
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useRef, createRef, useEffect, useState } from "react";
import { Avatar, Typography, Paper, IconButton, ButtonGroup, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';


  
  const ITEM_HEIGHT = 48;
  
 export default function CategoriesMenu({options, selected, handleChange}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
      setAnchorEl(null);
    };
    const handleChangeOption = (option) => {
        handleChange(option.hash)()
      setAnchorEl(null);
    };
    return (
      <Box mr={1} ml={-1}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 12.5,
              width: '70vw',
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.text} selected={option.hash === selected} onClick={(e) => handleChangeOption( option)}>
             <Typography variant="text"> {option.text}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  }