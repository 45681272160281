export default function featuresReducer(state, action) {
    switch (action.type) {
        case 'INIT': {
            const newState = {
                ...state, features: action.payload.reduce(function (map, obj) {
                    map[obj.key] = obj.payload;
                    return map;
                }, {})
            }
            localStorage.setItem('features', JSON.stringify(newState))
            return newState
        }
        default:
            return state;
    }
}