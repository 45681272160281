import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Paper, Box, Avatar, Icon } from '@mui/material';
import Grid from '@mui/material/Grid'
import Tag from '../Tag'
import Divider from '@mui/material/Divider';
import distance from './distance.png'
import like from './like.png'
import { useHistory } from "react-router-dom";
import { BorderAllRounded } from '@mui/icons-material';
import { borderRadius } from '@mui/system';




export default function Restaurant({ restaurant, widthBox="100vw", widthAvatar="96vw", height="22vh", hasTags=true }) {

    const {coverUrlImg, urlKey, id, name, description} = restaurant

    const tags = [{ value: "🍕", color: 0 }, { value: "🍗", color: 1 }]
   
    let history = useHistory();

    const handleOnClick = () => {
        history.push(urlKey);
    }

    return (

        <Box 
            display="flex" 
            flexDirection="column" 
            justifyContent="center" 
            alignItems="center"
            sx={{ width: widthBox}}
        >
            <Box >
                <Avatar
                        onClick={handleOnClick}
                        sx={{
                            borderRadius: 1,
                            width: widthAvatar,
                            height: height
                        }}
                        src={coverUrlImg}
                />
                
            </Box>


            <Box 
                mt={1}
                sx={{
                    width: widthAvatar
                }}
            >
                <Box alignSelf="flex-start" >
                    <Box display="flex" justifyContent="space-between">
                        <Typography sx={{fontWeight: "bold"}} variant="h5">
                            {name}
                        </Typography>
                        {/* <Typography variant="body1">
                            54km, 30min
                        </Typography> */}
                    </Box>

                    {/* <Typography variant="body2">
                        {description}
                    </Typography> */}
                    <Typography variant="body1">
                        54km, 30min
                    </Typography>

                    <Box>
                        {hasTags && tags.map((tag) => {
                            if (tag) {
                                return (
                                    <Tag tag={tag.value} color={tag.color} />
                                )
                            }
                        })}
                    </Box>

                </Box>

                
            </Box>

        </Box>

        
    );
}
