

import React, {useState} from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import Restaurant from "../restaurants/Restaurant";
import { Box , Avatar, Typography} from "@mui/material"
import Tag from '../Tag'
import useDrag from "./useDrag";
import green from '@mui/material/colors/green';
  import red from '@mui/material/colors/red';
  import pink from '@mui/material/colors/pink';
  import purple from '@mui/material/colors/purple';
  import deepPurple from '@mui/material/colors/deepPurple';
  import lightBlue from '@mui/material/colors/lightBlue';
  import lightGreen from '@mui/material/colors/lightGreen'
  import yellow from '@mui/material/colors/yellow'
  import amber from '@mui/material/colors/amber'
  import cyan from '@mui/material/colors/cyan'
  import blue from '@mui/material/colors/blue'
  import indigo from '@mui/material/colors/indigo'
  import teal from '@mui/material/colors/teal'
  import orange from '@mui/material/colors/orange'
  import deepOrange from '@mui/material/colors/deepOrange'
import "./NoScrollBar.css"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const colors = [ green, yellow, pink, purple, deepPurple, lightBlue, lightGreen, cyan, amber, blue, red, indigo, teal, orange, deepOrange]

export default function ScrollableTags({tags}) {
  const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag = ({ scrollContainer }) => (
    ev
  ) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  return (
    <>

      <Box  >
        <div onMouseLeave={dragStop}>
          <ScrollMenu
            onWheel={onWheel}
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
            scrollContainerClassName="NoScrollBar"
          >

            {tags.filter(t => t.imgUrl).map((tag) => (
            //   <Box mr={1} sx={{backgroundColor: colors[tag.color][100], borderRadius: 2 }}>
             <Box display="flex" flexDirection="column" alignItems="center"> 
             <Box display="flex" alignItems="center"  justifyContent="center" mr={1}  sx={{ backgroundColor: 'white', borderRadius: 100 }}>
                  
               {/* <Tag tag={tag.value} color={tag.color}/> */}
                <Avatar sx={{width: !matches ? "20vw" : "7vw", height: !matches ? "20vw" : "7vw"}} src={tag.imgUrl} />
              </Box>

               <Box>
               <Typography sx={{fontWeight: "fontWeightBold"}} variant="body1">
                    {tag.name}
                </Typography>
               </Box>
               </Box>
            ))}
          </ScrollMenu>
        </div>
       
      </Box>
    </>
  );
}

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

