import React, { useState, useRef, useEffect } from 'react';
import { useCart } from '../../context/cart/context'
import { useFeature, MODULE_ORDER } from '../../context/feature/context'
import CircularProgress from '@mui/material/CircularProgress';
import { backendUrl } from '../../index'
import useFetch from 'use-http'
import { Avatar, colors, Typography, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tag from '../Tag'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    useHistory,
    useLocation
} from "react-router-dom";
import { useParams } from 'react-router-dom'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import blue from '@mui/material/colors/blue';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';

import {
    Chip
} from '@mui/material'
const cover = {
    width: '100%',
    height: '45vh',
}


const paper = {
    width: '100%',
    height: '100%',
}


const DishDetails = () => {
    const { restaurantId, tableId, orderId, restaurantKey } = useParams()
    const { product, hasOrderModule } = useLocation().state
    const newTag = product.tags.map(tag => tag.value).includes("New")
    const popularTag = product.tags.map(tag => tag.value).includes("Popular")
    const deliveryOnlyTag = product.tags.map(tag => tag.value).includes("DeliveryOnly")

    const { state, dispatch } = useCart()
    const [counter, setCounter] = useState(1)
    const [details, setDetails] = useState()

    useEffect(() => {
        if (state.sessionId) {
            fetch(backendUrl + '/aaaa', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({ tableId, restaurantId, restaurantKey, userId: state.userId, sessionId: state.sessionId, page: window.location.href + "/?userId=" + state.userId })
            })
        }

    }, [state.sessionId])


    const history = useHistory()
    const { state: featureState } = useFeature()
    const { t } = useTranslation();

    const [toGo, setToGo] = useState(false)

    // const hasModuleOrder = featureState.features.hasOwnProperty(MODULE_ORDER)

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const onSubmit = () => {
        let restaurant = restaurantId
        if (!!restaurantKey) {
            restaurant = restaurantKey
        }

        if (restaurant !== state.restaurant) {
            dispatch({ type: 'reset' })
            dispatch({ type: 'setRestaurant', payload: restaurant})
        }

        if (hasOrderModule) {
            dispatch({ type: 'add', payload: { quantity: counter, id: product.id, title: product.name, price: (100 - (product.discount || 0)) * product.price / 100, currency: "RON", imgUrl: product.imgUrl, toGo: toGo, productInfo: details } })
        }
        history.goBack()
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (!product) return <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
    >
        <CircularProgress />
    </Box>

    return (

        <Box display='flex' flexDirection='column' justifyContent='space-between' sx={{ paper }}>

            <Box mb={11}>
                <Box >
                    <Fab size="small" onClick={() => history.goBack()} sx={{ position: 'absolute', top: '10px', left: !matches ? '10px' : 'calc(10px + 33vw)', zIndex: 1, backgroundColor: "#fafafa" }} aria-label="add">
                        <ArrowBackIcon />
                    </Fab>
                    {
                        (!product.imgUrl || product.imgUrl === 'http://res.cloudinary.com/daqirrhrb/image/upload/v1634771923/pn6dqajidofigjpiop1u.png' || product.imgUrl === 'https://ik.imagekit.io/lprqlslc6bt/cloudinary-images/pn6dqajidofigjpiop1u.png')
                        ?
                        <Box mt={7}/>
                        :
                        <Avatar
                            variant="square"
                            sx={cover} src={product.imgUrl}>
                        </Avatar>
                    }
                    

                    <Box>
                        <Box mt="10px" ml="10px" mb="10px" >
                            <Typography variant={product.name.length > 20 ? "h4" : "h3"} >
                                {product.name}
                            </Typography>
                        </Box>

                        <Box ml={1}  >
                            {product.tags
                                .filter(tag => tag.value !== "New")
                                .filter(tag => tag.value !== "Recomandate")
                                .filter(tag => tag.value !== "Story")
                                .filter(tag => tag.value !== "Popular")
                                .filter(tag => tag.value !== "BigPost")
                                .filter(tag => tag.value !== "DeliveryOnly")

                                .map(tag => {
                                    if (tag) {
                                        return (
                                            <Tag key={tag.id} tag={tag.value} color={tag.color} />
                                        )
                                    }
                                })}

                            {popularTag && <Chip
                                size="small"
                                variant="outlined"
                                clickable={true}
                                style={{ backgroundColor: green[700], border: '0px', marginRight: '5px', fontWeight: "bold", color: '#fff', fontSize: '10px' }}
                                key={"tag"}
                                label={"Popular"}
                            // onClick={onTagClick}
                            />}
                            {deliveryOnlyTag && <Chip
                                size="small"
                                variant="outlined"
                                clickable={true}
                                style={{

                                    backgroundColor: blue[600],
                                    border: '0px',
                                    marginRight: '5px',
                                    fontWeight: "bold",
                                    color: '#fff',
                                    fontSize: '10px'
                                }}
                                key={"tag"}
                                label={"Delivery Only"}
                            // onClick={onTagClick}
                            />}
                            {newTag && <Chip
                                size="small"
                                variant="outlined"
                                clickable={true}
                                style={{ backgroundColor: red[700], border: '0px', marginRight: '5px', fontWeight: "bold", color: '#fff', fontSize: '10px' }}
                                key={"new"}
                                label={"New"}
                            // onClick={onTagClick}
                            />}
                        </Box>

                        {/* <Box mt="10px" ml="10px" mb="10px" >
                            <Typography sx={{ fontWeight: "fontWeightBold" }} variant="h5" >
                                {product.price} RON
                            </Typography>
                        </Box> */}

                        <Box mt="10px" ml="10px" mb="10px" display="flex">
                            <Box mr={1}>
                                {(!product.discount || product.discount === 0) && <Typography sx={{ fontWeight: "fontWeightBold" }} variant="h6">
                                    {product.price} RON
                                    </Typography>}

                                { !!product.discount && product.discount !== 0 &&
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Typography mr={1} sx={{ color: "#bbbbbb", fontWeight: "fontWeightBold", textDecorationLine: 'line-through' }} variant="h6">
                                            {product.price} RON
                                        </Typography>

                                        <Typography mr={1} sx={{ fontWeight: "fontWeightBold", color: 'red' }} variant="h6">
                                            {(100 - product.discount) * product.price / 100} RON
                                        </Typography>

                                    </Box>}

                            </Box>
                        </Box>

                        <Box sx={{ "white-space": "pre-wrap" }} mt="10px" ml="10px" mb="10px" >

                            <Typography variant="h6" >
                                {product.description}
                            </Typography>
                        </Box>

                        {!restaurantKey && hasOrderModule && <Box mt="10px" ml="10px" mb="10px">
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={toGo} onChange={() => setToGo(!toGo)} />} label={t('La pachet')} />
                                {/* <FormControlLabel disabled control={<Checkbox defaultChecked />} label="Disabled" /> */}
                            </FormGroup>
                        </Box>}

                    </Box>
                </Box>

             { hasOrderModule &&
                    <Box mb={4} ml="10px" mr={2}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label={t('Informatii suplimentare')}
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                    />
                </Box>
             }

                {hasOrderModule && <Box sx={{ pb: '90px' }} mb={8} display="flex" justifyContent="center">
                    <Box ml={1} mr={1} onClick={() => { counter > 1 ? setCounter(counter - 1) : setCounter(1) }}>
                        <Fab sx={{ backgroundColor: "#fafafa" }} aria-label="add">
                            <RemoveIcon />
                        </Fab>
                    </Box>
                    <Box ml={1} mr={1}>
                        <Typography variant="h4">
                            {counter}
                        </Typography>
                    </Box>
                    <Box ml={1} mr={1} onClick={() => setCounter(counter + 1)}>
                        <Fab sx={{ backgroundColor: "#fafafa" }} aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Box>
                </Box>}

            </Box>

            <Box sx={{ position: 'fixed', bottom: 20, left: !matches ? 10 : 'calc(10px + 33vw)', right: !matches ? 10 : 'calc(10px + 33vw)' }} elevation={3}>
                <Button onClick={onSubmit} sx={{ minHeight: '60px', fontWeight: "fontWeightBold" }} size="large" variant="contained" fullWidth >
                    {hasOrderModule ? t('Adauga in cos') + ` - ${counter * (100 - (product.discount || 0)) * product.price / 100} RON` : t('Mergi inapoi la meniu')}
                </Button>
            </Box>


        </Box>


    )
}
export default DishDetails

