import React, {useState} from "react";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Box, Tooltip} from '@mui/material'
import { CopyToClipboard } from "react-copy-to-clipboard";


        //         <ContentCopyIcon/>
const CopyTextButton = ({text}) => {
    const [copiedText, setCopiedText] = useState(false);

    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  
    
    const handleCopyClick = () => {
      copyTextToClipboard(text)
        .then(() => {
            setCopiedText(true);
          setTimeout(() => {
            setCopiedText(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
  return (
    <>
        <Tooltip
            title={"Copied!"}
            placement="top"
            open={copiedText}
          >
        
            <ContentCopyIcon onClick={handleCopyClick} />
        
      </Tooltip>

      
    </>
  )
}
export default CopyTextButton