const state = {
    liked: [],
    userId: '', sessionId: '',
    items: [], ownerId: '', position: 0, language: 'ro', selected: 'left', orderId: '', userDetails: {
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        addressdetalis: '',
        phoneNumber: '',
        lat: null,
        lng: null
    },
    restaurant: ''
}
const stateFromStorage = JSON.parse(localStorage.getItem("state"))
export const initialState = localStorage.getItem("state")
    ? { ...stateFromStorage, position: 0, language: 'ro', selected: 'left', sessionId: '',
     userDetails: stateFromStorage.userDetails ? stateFromStorage.userDetails :  state.userDetails,
     liked: stateFromStorage.liked ? stateFromStorage.liked : state.liked}
     
    : state;

