import React ,{ useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import {backendUrl} from '../index'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({open, setOpen, color, tableId, restaurantId}) {
  const [load1, setLoad1] = useState(false)
  const [load2, setLoad2] = useState(false)

  const  handleClose1 = async () => {
    setLoad1(true)
    await fetch(backendUrl + '/notifications', {method: 'POST', headers: {
      'Content-Type': 'application/json'
    }, body: JSON.stringify({tableId, restaurantId, message: "Nota CARD la masa" })})
    setLoad1(false)
    setOpen(false);
  };

  const  handleClose2 = async () => {
    setLoad2(true)
    await fetch(backendUrl + '/notifications', {method: 'POST', headers: {
      'Content-Type': 'application/json'
    }, body: JSON.stringify({tableId, restaurantId, message: "Nota CASH la masa" })})
    setLoad2(false)
    setOpen(false);
  };

  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
            <Typography  variant="h6">Cum doresti sa platesti?</Typography>
        </DialogContent>
        <DialogActions>
          
          <Button disabled={load1} onClick={handleClose1} variant="contained"  sx={{'&:hover': { backgroundColor: '#808080' }, borderRadius: 0, fontWeight:"fontWeightBold", backgroundColor: "#fff", color: color}} >
          {!load1 && "Card"}  {load1 && <Box display="flex"
            justifyContent="center"
            alignItems="center"><CircularProgress sx={{ color: '#fff', fontWeight:"fontWeightBold" }} size={20} /></Box>}
 
          </Button>

          <Button  disabled={load2} onClick={handleClose2} variant="contained" sx={{'&:hover': { backgroundColor: '#808080' }, borderRadius: 0, fontWeight:"fontWeightBold", backgroundColor: "#fff", color: color}}>
          {!load2 && "Cash"}  {load2 && <Box display="flex"
            justifyContent="center"
            alignItems="center"><CircularProgress sx={{ color: '#fff', fontWeight:"fontWeightBold" }} size={20} /></Box>}
 
          </Button>
        </DialogActions>
      </Dialog>
  );
}