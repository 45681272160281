
import {v4 as uuidv4} from 'uuid';


export default function cartReducer(state, action) {
    switch (action.type) {
        case 'add':
            {
                const items = [...state.items]

                for (const product of items) {
                    if (product.id === action.payload.id) {

                        product.quantity = Math.max(1, product.quantity + action.payload.quantity)
                        const newState = {
                            ...state, items
                        }
                        localStorage.setItem('state', JSON.stringify(newState))
                        return newState
                    }
                }
                const newState = {
                    ...state,
                    items: [action.payload, ...state.items]
                };
                localStorage.setItem('state', JSON.stringify(newState))
                return newState
            }

        case 'remove':
            {
                const items = [...state.items]
                const newState = {
                    ...state, items: items.filter(product => product.id !== action.payload.id)
                }
                localStorage.setItem('state', JSON.stringify(newState))
                return newState
            }

        case 'reset': {

            const newState = { ...state, items: [], places: [] }
            localStorage.setItem('state', JSON.stringify(newState))
            return newState
        }

        case 'setLanguage': {
            const newState = { ...state, language: action.payload }
            return newState
        }

        case 'setPosition':
            return { ...state, position: action.payload }

        case 'change': {
            const newState = { ...state, selected: action.payload }
            return newState
        }

        case 'setOrderId': {
            const newState = { ...state, orderId: action.payload }
            localStorage.setItem('state', JSON.stringify(newState))
            return newState
        }

        case 'setRestaurant': {
            const newState = { ...state, restaurant: action.payload }
            localStorage.setItem('state', JSON.stringify(newState))
            return newState
        }

        case 'setUserId': {
            if (state.userId) 
                return state

            const newState = { ...state, userId: uuidv4() }
            localStorage.setItem('state', JSON.stringify(newState))
            return newState
        }

        case 'setSessionId': {
            if (state.sessionId) 
                return state
            const newState = { ...state, sessionId: uuidv4() }
            return newState
        }

        case 'addUserDetails': {
            const newState = { ...state, userDetails: {...action.payload} }
            localStorage.setItem('state', JSON.stringify(newState))
            return newState
        }

        case 'like': {
            const newState = { ...state, liked: [...state.liked, action.payload] }
            localStorage.setItem('state', JSON.stringify(newState))
            return newState
        }

        case 'dislike': {
            const newState = { ...state, liked: state.liked.filter(item => item !== action.payload) }
            localStorage.setItem('state', JSON.stringify(newState))
            return newState
        }

        default:
            return state;
    }
}