

import React, {useState} from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import Restaurant from "../restaurants/Restaurant";
import { Box } from "@mui/material"

import useDrag from "./useDrag";

import "./NoScrollBar.css"

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function ScrollableMenu({restaurants}) {

  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag = ({ scrollContainer }) => (
    ev
  ) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>

      <Box>
        <div onMouseLeave={dragStop}>
          <ScrollMenu
            onWheel={onWheel}
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
            scrollContainerClassName="NoScrollBar"
          >
            {restaurants.map((restaurant) => (
              <Box>
                <Restaurant restaurant={restaurant} widthAvatar={!matches ? "70vw" : "20vw"} widthBox={!matches ? "74vw" : "24vw"} height={!matches ? "18vh" : "18vh"} hasTags={false}/>
              </Box>
            ))}
          </ScrollMenu>
        </div>
       
      </Box>
    </>
  );
}

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

