export default function NotFound() {
    return (
      <div
        style={{ display: 'flex', flexdirection: 'column', alignItems: 'center' }}
      >
        <h2>404, page not found</h2>
        <p>Woops, there's nothing here!</p>
      </div>
    )
  }
  